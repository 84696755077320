var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password-page page-layout",class:{
    'app-jepa__background': _vm.isJEPA,
  },style:({ backgroundImage: _vm.backgroundUrl })},[_c('md-card',{staticClass:"meep-form"},[_c('md-card-content',[_c('div',{staticClass:"meep-input"},[_c('div',{staticClass:"meep-form-single-column"},[_c('div',{staticClass:"logo",domProps:{"innerHTML":_vm._s(_vm.logo)}}),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reset-password.title")))]),_c('div',{staticClass:"meep-input"},[_c('md-field',{class:{ 'md-invalid': _vm.errors.has('newPassword') }},[_c('label',[_vm._v(_vm._s(_vm.$t("reset-password.new-password"))+" *")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&+-^])[A-Za-z\d@#$!%*?&+-^]{8,}$/,
                }),expression:"{\n                  required: true,\n                  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$#!%*?&+-^])[A-Za-z\\d@#$!%*?&+-^]{8,}$/,\n                }"}],attrs:{"name":"newPassword","type":"password"},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('newPassword')),expression:"errors.has('newPassword')"}],staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("newPassword"))+" ")])],1),_c('div',{staticClass:"meep-input"},[_c('md-field',{class:{
                'md-invalid': _vm.errors.has('confirmNewPassword'),
              }},[_c('label',[_vm._v(_vm._s(_vm.$t("reset-password.confirm-new-password"))+" *")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  is: _vm.form.newPassword,
                }),expression:"{\n                  required: true,\n                  is: form.newPassword,\n                }"}],attrs:{"name":"confirmNewPassword","type":"password"},model:{value:(_vm.form.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmNewPassword", $$v)},expression:"form.confirmNewPassword"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('confirmNewPassword')),expression:"errors.has('confirmNewPassword')"}],staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("confirmNewPassword"))+" ")])],1)],1),_c('div',{staticClass:"md-layout md-alignment-center-right"},[_c('md-button',{staticClass:"md-primary reset-password-button",on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }