<template>
  <div
    :class="{
      'app-jepa__background': isJEPA,
    }"
    :style="{ backgroundImage: backgroundUrl }"
    class="reset-password-page page-layout"
  >
    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-input">
          <div class="meep-form-single-column">
            <div class="logo" v-html="logo" />
            <h2 class="title">{{ $t("reset-password.title") }}</h2>
            <!-- Le logo et le titre -->

            <!-- Nouveau mot de passe -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('newPassword') }">
                <label>{{ $t("reset-password.new-password") }} *</label>

                <md-input
                  v-model="form.newPassword"
                  v-validate="{
                    required: true,
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&+-^])[A-Za-z\d@#$!%*?&+-^]{8,}$/,
                  }"
                  name="newPassword"
                  type="password"
                />
              </md-field>

              <span v-show="errors.has('newPassword')" class="md-error">
                {{ errors.first("newPassword") }}
              </span>
            </div>

            <!-- Confirmer nouveau mot de passe -->
            <div class="meep-input">
              <md-field
                :class="{
                  'md-invalid': errors.has('confirmNewPassword'),
                }"
              >
                <label>{{ $t("reset-password.confirm-new-password") }} *</label>

                <md-input
                  v-model="form.confirmNewPassword"
                  v-validate="{
                    required: true,
                    is: form.newPassword,
                  }"
                  name="confirmNewPassword"
                  type="password"
                >
                </md-input>

                <span
                  v-show="errors.has('confirmNewPassword')"
                  class="md-error"
                >
                  {{ errors.first("confirmNewPassword") }}
                </span>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right">
              <!-- Le bouton d'envoi -->
              <md-button
                class="md-primary reset-password-button"
                @click="resetPassword"
              >
                {{ $t("send") }}
              </md-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import loginModel from "../model/login";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ResetPassword",

  beforeRouteEnter(to, from, next) {
    if (!to.params.token) next(from.path);
    next();
  },

  data() {
    return {
      isDebug: false,
      form: {
        newPassword: "",
        confirmNewPassword: "",
        token: this.$route.params.token,
      },
    };
  },

  computed: {
    ...mapGetters([
      "organizationTheme",
      "backgroundUrl",
      "isGC",
      "isLCM",
      "isJEPA",
    ]),

    ...mapState(["logo"]),
  },

  mounted() {
    const message = {
      custom: {
        newPassword: {
          regex: this.$t("error.password-strength"),
        },
      },
    };
    this.$validator.localize("fr", message);
  },

  methods: {
    resetPassword() {
      this.$validator.validateAll().then(result => {
        if (result) {
          loginModel
            .resetPassword(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: this.$t("reset-password.success"),
              });

              this.$router.push("/signin");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppError({
            message: this.$t("error.required"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variable.scss";

#app {
  .reset-password-page {
    &__corner-img {
      position: absolute;
      transform: rotate(90deg);
      top: 0;
      left: 0;
      width: 20%;

      &.logo-corner-lcm {
        top: 32px;
        width: 20em;
        transform: rotate(0) translate(-1em);
      }
    }

    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

    .meep-form {
      top: 15%;
      max-width: 1000px;
      margin: 0 auto;
      padding: toRem(50);
      @include for-sm {
        top: 20%;
        padding: toRem(70) toRem(60);
      }

      .logo {
        display: flex;
        justify-content: center;
        width: 234px;
        margin: 0 auto;

        svg {
          margin: 0 auto;
        }
      }

      .title {
        text-align: center;
        font-size: toRem(18);
        line-height: 1;
        font-family: var(--font-extrabold);
        margin: toRem(30) 0;
        @include for-lg{
        margin: 50px;
        font-size: toRem(22);
        }
      }
      .md-alignment-center-right {
        margin-top: 20px;

        .reset-password-button {
          width: 100%;
          margin: 0;
          @include for-sm{
            width: 180px;
            margin-right: 0;
          }
        }
      }
    }

    .md-error {
      color: var(--primary, #ff1744);
      font-size: 12px;
    }
  }
}
</style>
